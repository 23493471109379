import React, { useEffect, useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import bepassLogo from '../images/bepass_logo.png'
import tokioLogo from '../images/bepass_logo.png'
import fanLogo from '../assets/images/fanbase-logo.png'

const SideBar = () => {

  const activeRole = sessionStorage.getItem('role')

  const [orgLogo, setOrgLogo] = useState(tokioLogo)

  function logMeOut() {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('gate')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('company')
    return (window.location.href = '/')
  }




  useEffect(() => {
    const companyId = sessionStorage.getItem('company')
    if (companyId === '8e0638ba-bad9-4218-ad65-d0dbb2ea6fa6') {
      setOrgLogo(fanLogo)
    }

  }, [])

  return (
    <div className="custom-navBar">
      <CDBSidebar breakpoint={991}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none logo-header" style={{ color: 'inherit' }}>
            <img src={orgLogo} alt="BePass" />
          </a>
        </CDBSidebarHeader>


        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>

            {/* 
            <NavLink to="/dashboard" >
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink> */}

            {activeRole === 'fanbase' ? (
              <>
                <NavLink to="/dashboard/fanbase" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'admin' ? (
              <>
                <NavLink to="/dashboard" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/organizations" >
                  <CDBSidebarMenuItem icon="star">Organizações</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'localadmin' ? (
              <>
                <NavLink to="/home" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="star">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'coordinator' ? (
              <>
                <NavLink to="/dashboard/coordinator" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/events" >
                  <CDBSidebarMenuItem icon="circle">Eventos</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/providers" >
                  <CDBSidebarMenuItem icon="save">Fornecedores</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/user/admin" >
                  <CDBSidebarMenuItem icon="square">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'supplier' ? (
              <>
                <NavLink to="/dashboard/provider" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="square">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'local' ? (
              <>
                <NavLink to="/dashboard/local" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>


                <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="users">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) :
              <>
                <NavLink to="/events/d102f0e4-d3e7-4be8-a862-c4c60358dab2" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>
                {/* <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="users">Cadastrar Usuários</CDBSidebarMenuItem>
                </NavLink> */}
              </>

            }









          </CDBSidebarMenu>
        </CDBSidebarContent>


        <CDBSidebarFooter>



          <CDBSidebarMenu>
            <NavLink onClick={logMeOut} to="/" >
              <CDBSidebarMenuItem icon="sign-out-alt">Sair</CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>


          <div className='bepass-logo' >
            <img src={bepassLogo} alt="Bepass" />
            {/* <i className="fa fa-bars fa-large"></i> */}
          </div>
        </CDBSidebarFooter>

        {/* 
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Sidebar
          </a>
        </CDBSidebarHeader> */}

      </CDBSidebar>
    </div >
  );
};


export default SideBar;